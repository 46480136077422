<template>
  <div class="ranking" id="ranking">
    <HeaderCommon ref="header" title="ランキング">
      <template v-slot:headerButton>
        <ButtonBack />
      </template>
    </HeaderCommon>
    <div id="content-scroll" class="content-scroll">
      <div class="ranking__gender d-flex">
        <div
          class="_item f-w6 flex-center"
          v-for="(item, key) of tabSexOptions"
          :key="key"
          :class="item.sex === tabSex ? 'active' : ''"
          @click="changeTabSex(item.sex)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="ranking__content">
        <RankingTabs
          v-model="tabRankingType"
          :typeTab="tabSex"
          @choose-tab="changeTabRankingType($event)"
        />
        <RankingTimeline
          v-model="timeline"
          @choose-timeline="changeTimeline($event)"
        />
      </div>
      <div class="ranking__list d-flex flex-wrap">
        <template v-if="isLoading">
          <AppLoading />
        </template>
        <template v-else>
          <template v-if="usersRank.length > 0">
            <RankingItem
              v-for="(item, key) of usersRank"
              :rankType="tabRankingType"
              :key="key"
              :data="item"
              :clsObject="'rank__' + (key + 1)"
              :typeDisplay="tabSex"
            />
          </template>
          <template v-else>
            <p class="w-100">現在データはありません。</p>
          </template>
        </template>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import HeaderCommon from "@/views/Common/HeaderCommon.vue";
import ButtonBack from "@/views/Common/ButtonBack.vue";
import RankingTabs from "./RankingTabs";
import RankingTimeline from "./RankingTimeline";
import RankingItem from "./RankingItem";
import { mapGetters } from "vuex";
import CardFooter from "@/components/CardFooter";

export default {
  name: "Ranking",
  components: {
    HeaderCommon,
    ButtonBack,
    RankingTabs,
    RankingTimeline,
    RankingItem,
    CardFooter
  },
  metaInfo() {
    return {
      title: "ランキング",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      tabSex: 1,
      tabSexOptions: [
        { sex: 1, title: "メンズ" },
        { sex: 2, title: "ウィメンズ" }
      ],
      tabRankingType: 1,
      timeline: 1
    };
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    await this.$store.dispatch("auth/getUsersRank", {
      sex: 1,
      ranking_type: 1,
      period: 1
    });
    this.$store.dispatch("common/setIsLoading", false);
  },
  computed: {
    ...mapGetters({
      usersRank: "auth/usersRank",
      isLoading: "common/isLoading"
    })
  },
  methods: {
    async requestData() {
      this.$store.dispatch("common/setIsLoading", true);
      await this.$store.dispatch("auth/getUsersRank", {
        sex: this.tabSex,
        ranking_type: this.tabRankingType,
        period: this.timeline
      });
      this.$store.dispatch("common/setIsLoading", false);
    },
    // update sex tab
    changeTabSex(key) {
      this.tabSex = key;
      this.timeline = 1;
      if (this.tabSex === 1) {
        this.tabRankingType = 1;
      } else {
        this.tabRankingType = 6;
      }

      this.requestData();
    },
    // update ranking type and load data
    changeTabRankingType(rankingType) {
      this.tabRankingType = rankingType;
      this.timeline = 1;
      this.requestData();
    },
    // update timeline type
    changeTimeline(time) {
      this.timeline = time;
      this.requestData();
    }
  }
};
</script>

<style lang="scss" scoped>
.content-scroll {
  padding-top: 42px;
  padding-bottom: 100px;
  // overflow-y: scroll;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}
#ranking {
  // background-color: #f5f5f5;
  .btn-back {
    position: absolute;
    left: 19px;
  }

  .app-container {
    &__content {
      padding: 42px 0 65px;
    }
  }

  .header {
    background-color: #ffffff;
  }
}

.ranking {
  &__gender {
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #dcdcdc;
    ._item {
      cursor: pointer;
      flex: 1;
      position: relative;
      color: #9f9f9f;
      font-size: 14px;
      &.active {
        color: #000;
      }

      &:nth-child(1) {
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 1px;
          height: 25px;
          border-radius: 2px;
          background-color: #b9b9b9;
        }
      }
    }
  }

  &__content {
    padding: 12px 19px;
  }
}

.ranking {
  &__list {
    max-width: 375px;
    margin: 23px auto;
  }
}

@media only screen and (min-width: 1200px) {
  .content-scroll {
    padding-top: 61px;
    // height: calc(100vh - 152px);
  }

  #ranking {
    .app-container {
      &__content {
        padding: 61px 0;
      }
    }
  }

  .ranking {
    &__content {
      margin: 0 auto;
      width: 558px;
    }

    &__gender {
      height: 70px;

      ._item {
        font-size: 18px;
      }
    }

    &__content {
      padding: 15px 0;
    }

    &__list {
      max-width: 610px;
      margin-top: 55px;
    }

    &__item {
      margin-right: 0px;
      .avatar-box {
        width: 118px;
        height: 118px;
      }
    }
  }
}
</style>
