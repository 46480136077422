<template>
  <div
    class="ranking__item d-flex flex-column align-items-center"
    :class="clsObject"
  >
    <div class="ranking__avatar">
      <AvatarBox imgSize="75px" :imgUrl="imageUrl" :imgAlt="data.nickname" />
    </div>

    <span class="f-w3 _name">{{ data.nickname }}</span>
    <span v-if="typeDisplay === 1" class="f-w6 _point"
      >{{ pointForRankType }}P</span
    >
  </div>
</template>

<script>
import AvatarBox from "@/views/Common/AvatarBox";
import { NumberFormat } from "@/utils/number";

export default {
  name: "RankingItem",
  components: {
    AvatarBox
  },
  props: {
    data: {
      type: Object,
      require: true
    },
    clsObject: {
      type: String
    },
    typeDisplay: {
      type: Number
    },
    rankType: {
      type: Number
    }
  },
  computed: {
    imageUrl() {
      if (this.data.image_url) {
        return this.data.image_url;
      }
      return "";
    },
    pointForRankType() {
      let point = "";
      switch (this.rankType) {
        case 2: // evaluation
          point = this.data.evaluation;
          break;
        case 3: // order points
          point = this.data.order_points;
          break;
        case 4: // tipping points
          point = this.data.tipping_points;
          break;
        case 5: // tipped points
          point = this.data.tipped_points;
          break;
        default:
          // case 1 || 6
          point = this.data.score;
          break;
      }

      return NumberFormat.format(point);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";

.ranking {
  &__item {
    min-height: 140px;
    width: calc(100% / 3);
    margin-bottom: 20px;
    &:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
    .avatar-box {
      box-shadow: 0 4px 6px #00000029;
    }

    ._name {
      font-size: 14px;
    }

    ._point {
      font-size: 14px;
    }

    &.rank__1 {
      .ranking__avatar {
        &::after {
          background-image: url("../assets/image/rank/rank_1.png");
        }
      }
    }
    &.rank__2 {
      .ranking__avatar {
        &::after {
          background-image: url("../assets/image/rank/rank_2.png");
        }
      }
    }
    &.rank__3 {
      .ranking__avatar {
        &::after {
          background-image: url("../assets/image/rank/rank_3.png");
        }
      }
    }
    &.rank__4 {
      .ranking__avatar {
        &::after {
          background-image: url("../assets/image/rank/rank_4.png");
        }
      }
    }
    &.rank__5 {
      .ranking__avatar {
        &::after {
          background-image: url("../assets/image/rank/rank_5.png");
        }
      }
    }
    &.rank__6 {
      .ranking__avatar {
        &::after {
          background-image: url("../assets/image/rank/rank_6.png");
        }
      }
    }
  }

  &__avatar {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      position: absolute;
      top: -27px;
      left: 50%;
      transform: translateX(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 115px;
      height: 125px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .ranking {
    &__avatar {
      margin-bottom: 35px;
      .avatar-box {
        width: 115px !important;
        height: 115px !important;
      }

      &::after {
        top: -22px;
        width: 177px;
        height: 162px;
      }
    }

    &__item {
      margin-bottom: 25px;
      min-height: 185px;
      ._name,
      ._point {
        font-size: 18px;
      }
      &:not(:nth-child(3n)) {
        margin-right: 0;
      }
    }
  }
}
</style>
