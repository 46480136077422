<template>
  <div class="avatar-box" :style="{ width: imgSize, height: imgSize }">
    <img :src="imgUrl" :alt="imgAlt" />
  </div>
</template>

<script>
export default {
  name: "AvatarBox",
  props: {
    imgUrl: {
      // type: [String, null],
      required: true
    },
    imgAlt: {
      type: String,
      default: "Image"
    },
    imgSize: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar-box {
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 1080px) {
    .image-avatar {
      width: 68px;
      height: 68px;
    }
  }
}
</style>
