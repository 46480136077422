<template>
  <div class="ranking__timeline d-flex justify-content-center">
    <div
      class="_flag f-w3"
      v-for="(item, key) of timelines"
      :key="key"
      :class="item.dateType === value ? 'active' : ''"
      @click="chooseTimeline(item.dateType)"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "RankingTimeline",
  props: ["value"],
  data() {
    return {
      timelines: [
        { dateType: 1, title: "昨日" }, // Yesterday
        { dateType: 2, title: "先週" }, // Last 7 days
        { dateType: 3, title: "先月" }, // Last 30 days
        { dateType: 4, title: "全期間" } // Whole period
      ]
    };
  },
  methods: {
    chooseTimeline(type) {
      this.$emit("choose-timeline", type);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";

.ranking {
  &__timeline {
    position: relative;
    margin-top: 32px;

    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      top: -22px;
      width: 48px;
      height: 15px;
      @include backgroundImage(
        "../assets/image/rank/rank_cover.svg",
        contain,
        center,
        no-repeat
      );
    }

    &::before {
      width: 301px;
      height: 6px;
      top: -4px;
      @include backgroundImage(
        "../assets/image/rank/stick.svg",
        contain,
        center,
        no-repeat
      );
    }

    ._flag {
      position: relative;
      width: 54px;
      height: 85px;
      color: #fff;
      font-size: 14px;
      padding-top: 24px;
      cursor: pointer;
      user-select: none;
      @include backgroundImage(
        "../assets/image/rank/flag.svg",
        contain,
        center,
        no-repeat
      );

      &.active {
        background-image: url("../assets/image/rank/flag_active.svg");
      }

      &:not(:last-child) {
        margin-right: 18px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .ranking {
    &__timeline {
      margin-top: 55px;
      &::after {
        width: 89px;
        height: 27px;
        top: -35px;
        background-image: url("../assets/image/rank/rank_cover_pc.svg");
      }

      &::before {
        width: 558px;
        height: 11px;
        top: -6px;
        background-image: url("../assets/image/rank/stick_pc.svg");
      }

      ._flag {
        width: 100px;
        height: 158px;
        font-size: 20px;
        padding-top: 50px;
        background-image: url("../assets/image/rank/flag_pc.svg");
        &:not(:last-child) {
          margin-right: 33px;
        }

        &.active {
          background-image: url("../assets/image/rank/flag_active.svg");
        }
      }
    }
  }
}
</style>
