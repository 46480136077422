<template>
  <div class="ranking__tabs d-flex">
    <div
      class="_item f-w6 flex-center"
      v-for="(item, key) of tabs"
      :key="key"
      :class="item.rankingType === value ? 'active' : ''"
      @click="chooseTab(item.rankingType)"
    >
      <span v-html="item.title"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RankingTabs",
  props: ["value", "typeTab"],
  data() {
    return {
      tabsMale: [
        { rankingType: 1, title: "スコアランキング" }, // score
        { rankingType: 2, title: "プレゴランキング" }, // Evalution
        { rankingType: 4, title: "ギフト" } // Tipping_points
      ],
      tabsFemale: [
        // { rankingType: 2, title: "総合 </br> ランキング" },
        { rankingType: 6, title: "新人 </br> ランキング" }, // Rookie
        { rankingType: 5, title: "ギフト </br> ランキング" }, // Tipped
        { rankingType: 1, title: "スコア </br> ランキング" }
      ]
    };
  },
  computed: {
    tabs() {
      let tab = [];
      if (this.typeTab === 1) {
        tab = this.tabsMale;
      } else {
        tab = this.tabsFemale;
      }

      return tab;
    }
  },
  methods: {
    chooseTab(ranking_type) {
      this.$emit("choose-tab", ranking_type);
    }
  }
};
</script>

<style lang="scss" scoped>
.ranking {
  &__tabs {
    border-radius: 20px;
    overflow: hidden;
    background-color: #e8e8e8;
    ._item {
      font-size: 10px;
      flex: 1;
      color: #9f9f9f;
      height: 32px;
      line-height: 13px;
      cursor: pointer;
      user-select: none;
      &.active {
        border-radius: 20px;
        color: #fff;
        background-color: #464d77;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .ranking {
    &__tabs {
      border-radius: 30px;
      ._item {
        height: 55px;
        font-size: 16px;
        line-height: 22.5px;

        &.active {
          border-radius: 30px;
        }
      }
    }
  }
}
</style>
